import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/all_products/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/all_products/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="content">
              <div className="gallery-page">
                <h1 className="gallery-page__title">
                  Todos os Produtos PIZ BUIN<sup>®</sup>
                </h1>
                <div className="clearfix">
                  <div className="gallery-page__filter hidden">
                    <div className="arrow">
                      <br />
                    </div>
                    <form method id="fmFilter">
                      <select
                        name="filter"
                        className="gallery-page__filter__select"
                      >
                        <optgroup>
                          <option value="0">Filter by: All</option>
                          <option value="1,2,6,9,12,13" />
                          <option value="12,18,19" />
                          <option value="1,2,4" />
                          <option value="1" />
                          <option value="13" />
                        </optgroup>
                      </select>
                    </form>
                  </div>
                </div>
                <div className="innerContent clearfix gallery-page__list">
                  <a
                    href="/pt/nossos-produtos/hydro-infusion/#sun-gel-cream-face"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-hydro-infusion-cream-50spf-50ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Hydro Infusion
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN GEL CREAM FACE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/hydro-infusion/#sun-gel-cream"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-hydro-infusion-sun-gel-cream-50spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Hydro Infusion
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN GEL CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/active-protect/#actect"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-active-protect-sun-lotion-100ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Active &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/moisturising/#lo-o-solar-hidratante"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lotion-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOÇÃO SOLAR HIDRATANTE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/moisturising/#stick-labial-solar"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lipstick-aloe-vera-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          STICK LABIAL SOLAR
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/moisturising/#spray-solar-hidratante-ultra-light"
                    className="gallery-page__list__item"
                    data-pid="2"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/8bf310057998eb9035da232074f98217_f33.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY SOLAR HIDRATANTE ULTRA LIGHT
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/tan_protect/#-leo-spray-acelerador-de-bronzeado"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/597e55fa85eadc497dda9761c9c29e48_f305.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          ÓLEO SPRAY ACELERADOR DE BRONZEADO
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/tan_protect/#lo-o-solar-intensificadora-do-bronzeado"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/cdb96eb7dd5017e10ae2166b22fc0b37_f28.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOÇÃO SOLAR INTENSIFICADORA DO BRONZEADO
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/tan_protect/#spray-solar-intensificador-do-bronzeado"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/33e7e9d0e3e36224abe8382693902bac_f547.png" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY SOLAR INTENSIFICADOR DO BRONZEADO
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/instantglow/#spray-solar-iluminador"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-instant-glow-skin-illuminating-sun-spray-30spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY SOLAR ILUMINADOR
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/instantglow/#lo-o-solar-iluminadora"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-instant-glow-skin-illuminating-50spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOÇãO SOLAR ILUMINADORA
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/allergy/#lo-o-allergy-pele-sens-vel-ao-sol"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-sun-sensitive-skin-lotion-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOÇÃO ALLERGY PELE SENSÍVEL AO SOL
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/allergy/#spray-allergy-pele-sens-vel-ao-sol"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-sun-sensitive-skin-spray-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY ALLERGY PELE SENSÍVEL AO SOL
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/allergy/#creme-facial-allergy-pele-sens-vel-ao-sol"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-face-cream-50spf-50ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          CREME FACIAL ALLERGY PELE SENSÍVEL AO SOL
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/aftersun/#lo-o-hidratante-suavizante-refrescante"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-soothing-cooling-moisturising-lotion-200ml-pt-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOÇÃO HIDRATANTE SUAVIZANTE &amp; REFRESCANTE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/aftersun/#lo-o-hidratante-intensificadora-do-bronzeado"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-tan-intensifying-moisturising-lotion-200ml-pt-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOÇÃO HIDRATANTE INTENSIFICADORA DO BRONZEADO
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/pt/nossos-produtos/aftersun/#after-sun-al-vio-imediato-bruma-express-"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-instant-mist-spray-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          AFTER SUN ALÍVIO IMEDIATO BRUMA EXPRESS{" "}
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $(".heroImage > img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$(".heroImage").empty();\n})\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
